import React, {
  useReducer,
  createContext,
  useContext,
  useCallback
} from "react";
import CryptoJS from "crypto-js";
import JSEncrypt from "jsencrypt";
import cartReducer from "reducers/cartReducer";
import {
  addToCart,
  getCart,
  deleteCart,
  removeProductFromCart,
  removeAllFromCart,
  calculatePrices,
  setCoupon,
  sendOrder,
  getRsaKey
} from "utils/dataHandler";
import { NotificationContext } from "./NotificationContext";
import EncryptedData from "models/encrypted.model";

export const CartContext = createContext();

const CartContextProvider = ({ children }) => {
  const { notificationModels } = useContext(NotificationContext);

  const initState = getCart();

  const [cart, dispatch] = useReducer(cartReducer, initState);

  const getPrices = useCallback(order => {
    return calculatePrices(order)
      .then(res => Promise.resolve(res))
      .catch(err => Promise.reject(err));
  }, []);

  const addProduct = product => {
    if (product.id) {
      const updatedCart = addToCart(product);
      notificationModels.successAddToCart();
      dispatch({ type: "SET_CART", payload: updatedCart });
    } else notificationModels.serverError();
  };

  const removeProduct = product => {
    const updatedCart = removeProductFromCart(product);
    dispatch({ type: "SET_CART", payload: updatedCart });
    notificationModels.successRemoveFromCart();
  };

  const removeAll = product => {
    const updatedCart = removeAllFromCart(product);
    dispatch({ type: "SET_CART", payload: updatedCart });
    notificationModels.successRemoveFromCart();
  };

  const redeemCopuon = coupon => {
    const { products, coupon: actCoupon } = cart;
    return getPrices({ products, coupon })
      .then(({ coupon }) => {
        if (!actCoupon) {
          setCoupon(coupon);
          dispatch({ type: "SET_COUPON", payload: coupon });
          notificationModels.successRedeemCoupon();
        } else return Promise.reject("USED_COUPON");
      })
      .catch(err => {
        let errMsg = null;
        if (err === "INVALID_COUPON" || err === "INACTIVE_COUPON")
          errMsg = "invalidCoupon";
        else if (err === "USED_COUPON") errMsg = "usedCoupon";
        else notificationModels.serverError();
        return Promise.reject(errMsg);
      });
  };

  const submitOrder = order => {
    return sendOrder(order)
      .then(url => {
        if (url) {
          window.open(url, "_self");
        } else {
          deleteCart();
          dispatch({ type: "SET_CART", payload: getCart() });
          notificationModels.successSubmitOrder();
        }
      })
      .catch(err => {
        if (err === "TRANSACTION_REJECTED")
          notificationModels.cardPaymentFailed();
        else if (err === "ORDER_OUT_OF_SERVICE")
          notificationModels.orderingIsNotActive();
        else notificationModels.serverError();
        return Promise.reject(err);
      });
  };

  const successCardPayment = () => {
    deleteCart();
    dispatch({ type: "SET_CART", payload: getCart() });
    notificationModels.successSubmitOrder();
  };

  const encryptCardData = card =>
    getRsaKey().then(({ userId, rsaPublicKey }) => {
      const secretPhrase = CryptoJS.lib.WordArray.random(16);
      const salt = CryptoJS.lib.WordArray.random(128 / 8);
      const aesKey = CryptoJS.PBKDF2(secretPhrase.toString(), salt, {
        keySize: 128 / 32
      });
      const aesOptions = {
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        iv: CryptoJS.enc.Utf8.parse(userId.slice(0, 16))
      };
      const aesEncCardData = CryptoJS.AES.encrypt(
        JSON.stringify(card),
        aesKey,
        aesOptions
      );
      const rsaEncrypt = new JSEncrypt();
      rsaEncrypt.setPublicKey(rsaPublicKey);
      const rsaEncryptedAesKey = rsaEncrypt.encrypt(
        aesEncCardData.key.toString()
      );

      return new EncryptedData(
        userId,
        aesEncCardData.toString(),
        rsaEncryptedAesKey
      );
    });

  return (
    <CartContext.Provider
      value={{
        cart,
        addProduct,
        removeProduct,
        removeAll,
        getPrices,
        redeemCopuon,
        submitOrder,
        encryptCardData,
        successCardPayment
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartContextProvider;
