import React, { useContext, useState } from "react";
import { Button } from "style/global.styled";
import { TextField } from "components/utils";
import styled from "styled-components";
import useFormValues from "hooks/useFormValues";
import Coupon from "models/coupon.model";
import { useTranslation } from "react-i18next";
import { NotificationContext } from "contexts/NotificationContext";

const Wrapper = styled.div`
  max-width: 700px;
  margin: 0 auto;
`;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  & > * {
    :nth-child(1) {
      width: calc(50% - 10px);
      margin-right: 10px;
    }
    :nth-child(2) {
      width: calc(50% - 10px);
      margin-left: 10px;
    }
    :last-child {
      width: 100%;
    }
  }
`;

const AddCoupon = ({ onSubmit }) => {
  const { t: translate } = useTranslation();
  const { notificationModels } = useContext(NotificationContext);

  const initValues = { code: "", discount: "", isActive: true };

  const [discountError, setDiscountError] = useState("");
  const [values, handleChange, setValues] = useFormValues(initValues);

  const handleSubmit = e => {
    e.preventDefault();
    if (!discountError)
      onSubmit(new Coupon(values))
        .finally(() => {
          notificationModels.successAdded();
          setValues(initValues);
        })
        .catch(err => console.log(err));
  };

  const fields = translate("dashboard.coupons.headers", {
    returnObjects: true
  });

  const checkValidDiscount = () => {
    const discount = parseInt(values.discount);
    if (discount < 1 || discount > 100) setDiscountError("discountNotValid");
    else setDiscountError("");
  };

  return (
    <Wrapper>
      <Form onSubmit={handleSubmit}>
        <TextField
          name="code"
          label={fields[0].text}
          value={values.code}
          onChange={handleChange}
          required
        />
        <TextField
          name="discount"
          type="number"
          label={fields[1].text}
          value={values.discount}
          onChange={handleChange}
          onBlur={checkValidDiscount}
          error={discountError && translate(`error.${discountError}`)}
          required
        />
        <Button type="submit">{translate("add")}</Button>
      </Form>
    </Wrapper>
  );
};

export default AddCoupon;
