import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { IconContainer } from "style/global.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faMapMarkerAlt,
  faPhone,
  faAt
} from "@fortawesome/free-solid-svg-icons";

const Wrapper = styled.footer`
  min-height: 30vh;
  padding: 40px 20px;
  color: ${props => props.theme.colors.primary};
  background: ${props => props.theme.colors.darkerSecondary};
  display: flex;
  align-items: center;
  flex-direction: column;
  a:hover {
    text-decoration: underline;
  }
`;

const Img = styled.img`
  width: 300px;
  align-self: center;
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    margin: 10px 0;
  }
`;

const Content = styled.div`
  width: 90%;
  display: flex;
  text-align: center;
  align-items: flex-start;
  justify-content: space-between;
  margin: 40px 0;
  @media (max-width: 1200px) {
    width: 100%;
  }
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    margin: 10px 0;
  }
`;

const Item = styled.div`
  margin: 0 40px;
  @media (max-width: 900px) {
    margin: 40px 0;
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 30px;
`;

const Texts = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  ${Item}:last-child & {
    grid-template-columns: 1fr;
  }
  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;

const Text = styled.div`
  display: flex;
  ${IconContainer} {
    width: 20px;
    margin-right: 10px;
  }
  @media (max-width: 900px) {
    justify-content: center;
  }
`;

const CopyrightText = styled.div`
  margin: 40px 0;
  text-align: center;
`;

const Footer = () => {
  const { t: translate } = useTranslation();
  library.add(fab);

  const icons = [faMapMarkerAlt, faAt, faPhone, ["fab", "facebook-f"]];

  const contacts = translate("footer.contacts", { returnObjects: true });
  const openingHours = translate("footer.openingHours", {
    returnObjects: true
  });
  const copyrightTexts = translate("footer.copyrightTexts", {
    returnObjects: true
  });

  return (
    <Wrapper>
      <Content>
        <Img src={translate("logoImg")} alt="logo" />
        <Item>
          <Title>{translate("footer.contactsTitle")}</Title>
          <Texts>
            {contacts.map((text, index) => (
              <Text key={index}>
                <IconContainer>
                  <FontAwesomeIcon icon={icons[index]} />
                </IconContainer>
                {index !== contacts.length - 1 ? (
                  <div>{text}</div>
                ) : (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.facebook.com/fanyuvoetterem/"
                  >
                    {text}
                  </a>
                )}
              </Text>
            ))}
          </Texts>
        </Item>
        <Item>
          <Title>{translate("footer.openingHoursTitle")}</Title>
          <Texts>
            {openingHours.map((text, index) => (
              <Text key={index}>{text}</Text>
            ))}
          </Texts>
        </Item>
      </Content>
      <CopyrightText>
        {copyrightTexts.map((item, index) => (
          <span key={index}>
            {item.link ? (
              <a target="_blank" rel="noopener noreferrer" href={item.link}>
                {item.text}
              </a>
            ) : (
              <span>{item.text}</span>
            )}
            {copyrightTexts.length - 1 > index && <span> | </span>}
          </span>
        ))}
      </CopyrightText>
    </Wrapper>
  );
};

export default Footer;
