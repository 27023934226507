import React from "react";
import styled from "styled-components";
import { Selector } from "components/utils";
import { useTranslation } from "react-i18next";
import { ROLES } from "utils/contants";

const CustomSelector = styled(Selector)`
  padding: 0;
  width: 70%;
  min-width: 140px;
  select {
    height: 40px;
    text-align-last: center;
  }
  option {
    text-align: center;
  }
`;

const RoleSelector = ({ defaultValue, onChange }) => {
  const { t: translate } = useTranslation();

  const handleChange = e => {
    const { name, value } = e.target;
    onChange({ name, value });
  };

  return (
    <CustomSelector
      name="role"
      defaultValue={defaultValue}
      onChange={handleChange}
    >
      {Object.values(ROLES).map((role, index) => (
        <option key={index} value={role}>
          {translate(`dashboard.users.roles.${role}`)}
        </option>
      ))}
    </CustomSelector>
  );
};

export default RoleSelector;
