import React from "react";
import styled from "styled-components";
import { IconContainer, SubTitle } from "style/global.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Icon = styled(IconContainer)`
  font-size: 30px;
  width: 80px;
  height: 80px;
  color: ${props => props.theme.colors.secondary};
  border: 2px solid;
  border-radius: 50%;
`;

const Text = styled.div`
  font-size: 20px;
  max-width: 500px;
  color: ${props => props.theme.colors.secondary};
`;

const Message = ({ icon, title, text }) => {
  return (
    <>
      {icon && (
        <Icon>
          <FontAwesomeIcon icon={icon} />
        </Icon>
      )}
      <SubTitle color="dark">{title}</SubTitle>
      <Text>{text}</Text>
    </>
  );
};

export default Message;
