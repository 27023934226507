import React, { useContext } from "react";
import styled, { css } from "styled-components";
import { IconContainer, TableRow, TableColumn } from "style/global.styled";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { CartContext } from "contexts/CartContext";
import Product from "models/product.model";

const Column = styled(TableColumn)`
  @media (max-width: 768px) {
    margin: 10px 0;
    span {
      display: unset;
    }
    :not(:nth-child(5)) {
      width: 100%;
      justify-content: space-between;
    }
    :nth-child(5) {
      width: 100%;
      ${IconContainer} {
        width: 100%;
        padding: 10px;
        border-radius: 6px;
        background: ${props => props.theme.colors.shadow};
      }
    }
  }
`;

const Quantity = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  input {
    text-align: center;
    height: 32px;
    width: 32px;
    border-radius: 6px;
    border: 0;
    font-size: 16px;
    color: ${props => props.theme.colors.primary};
    background: ${props => props.theme.colors.secondary};
    -moz-appearance: textfield;
    ::-webkit-inner-spin-button {
      display: none;
    }
  }
  ${IconContainer} {
    :first-child {
      margin-right: 20px;
      ${props =>
        props.disabled &&
        css`
          color: ${props.theme.colors.transparentSecondary};
          pointer-events: none;
        `}
    }
    :last-child {
      margin-left: 20px;
    }
  }
`;

const Icon = styled(IconContainer)`
  font-size: 15px;
  cursor: pointer;
`;

const ProductRow = ({ product, quantity }) => {
  const { addProduct, removeProduct, removeAll } = useContext(CartContext);
  const { t: translate, i18n } = useTranslation();

  const { name, price, nameEnglish } = product;
  const lang = i18n.language;

  const headers = translate("cart.headers", { returnObjects: true });

  const increase = () => {
    addProduct(new Product(product));
  };

  const decrease = () => {
    if (quantity > 1) removeProduct(new Product(product));
  };

  const deleteAll = () => {
    removeAll(new Product(product));
  };

  return (
    <TableRow>
      <Column>{lang === "hu" ? name : nameEnglish ? nameEnglish : name}</Column>
      <Column>
        <span>{headers[1]}: </span>
        <Quantity disabled={quantity === 1}>
          <Icon onClick={decrease}>
            <FontAwesomeIcon icon={faMinus} />
          </Icon>
          <input type="number" value={quantity} readOnly />
          <Icon onClick={increase}>
            <FontAwesomeIcon icon={faPlus} />
          </Icon>
        </Quantity>
      </Column>
      <Column>
        <span>{headers[2]}: </span>
        {price} {translate("forint")}
      </Column>
      <Column>
        <span>{headers[3]}: </span>
        {price * quantity} {translate("forint")}
      </Column>
      <Column>
        <Icon onClick={deleteAll}>
          <FontAwesomeIcon icon={faTrash} />
        </Icon>
      </Column>
    </TableRow>
  );
};

export default ProductRow;
