import lactose from "assets/images/lactose-free.svg";
import gluten from "assets/images/gluten-free.svg";
import hot from "assets/images/hot.svg";
import vegetarian from "assets/images/vegetarian.svg";

export const PRODUCT_GROUP = {
  favourites: "FAVOURITES",
  appetisers: "STARTER",
  snack: "SNACK",
  soup: "SOUP",
  salad: "SALAD",
  "iron-plate": "IRONPLATE",
  cassic: "CLASSIC",
  mediterranean: "MEDITERRANEAN",
  quesadilla: "QUESADILLA",
  retro: "RETRO",
  dessert: "DESSERT",
  "side-dish": "SIDE_DISH",
  pickle: "PICKLE",
  "soft-drink": "SOFT_DRINK",
  "fruit-juice": "FRUIT_JUICE",
  "ice-tea": "ICE_TEA",
  lemonade: "LEMONADE",
  "energy-drink": "ENERGY_DRINK",
  "mineral-water": "MINERAL_WATER",
  "coffee-tea": "COFFEE_TEA",
  "draft-beer": "DRAFT_BEER",
  "bottle-beer-cider": "BOTTLE_BEER_CIDER",
  cocktail: "COCKTAIL",
  spirit: "SPIRIT",
  champagne: "CHAMPAGNE",
  wine: "WINE"
};

export const PAYMENT_METHOD = {
  CASH: "CASH",
  CREDIT_CARD_ONLINE: "CREDIT_CARD_ONLINE",
  CREDIT_CARD: "CREDIT_CARD",
  RESTAURANT_CASH: "RESTAURANT_CASH",
  RESTAURANT_CARD: "RESTAURANT_CARD"
};

export const ROLES = {
  CUSTOMER: "CUSTOMER",
  SHOP_ASSISTANT: "SHOP_ASSISTANT",
  MANAGER: "MANAGER",
  ADMIN: "ADMIN"
};

export const ORDER_STATE = {
  NEW: "NEW",
  PREPARING: "PREPARING",
  DELIVERING: "DELIVERING",
  DELIVERED: "DELIVERED"
};

export const ORDER_SORT_TYPE = {
  id: "id",
  date: "orderDate",
  status: "state",
  paid: "payed",
  price: "discountedPrice"
};

export const USER_SORT_TYPE = {
  id: "id",
  date: "registrationDate",
  name: "name",
  email: "email",
  role: "role"
};

export const PRODUCT_SORT_TYPE = {
  name: "name",
  nameE: "nameEnglish",
  type: "type",
  group: "group",
  orderable: "orderable"
};

export const BOOLEAN_STATE = {
  true: "yes",
  false: "no"
};

export const CARD_TYPES = {
  VISA: "VISA",
  MASTERCARD: "MASTERCARD"
};

export const PRODUCT_TYPES = {
  FOOD: "foods",
  DRINK: "drinks"
};

export const ALLERGEN_ICONS = {
  GLUTEN_FREE: gluten,
  LACTOSE_FREE: lactose,
  HOT: hot,
  VEGETARIAN: vegetarian
};

export const ALLERGENS = {
  GLUTEN_FREE: "GLUTEN_FREE",
  LACTOSE_FREE: "LACTOSE_FREE",
  HOT: "HOT",
  VEGETARIAN: "VEGETARIAN"
};

export const ORDERABLE = {
  orderable: true,
  notOrderable: false
};
