const images = {
  LogoSmall: require("./logos/fanyuvo_logo.png"),
  LogoBig: require("./logos/fanyuvo_logo_big.png"),
  LogoSmallEn: require("./logos/fanyuvo_logo_en.png"),
  LogoBigEn: require("./logos/fanyuvo_logo_big_en.png"),
  Background: require("./backgrounds/fanyuvo_background.jpg"),
  BackgroundLight: require("./backgrounds/fanyuvo_background_light.jpg"),
  BackgroundHeader: require("./backgrounds/fanyuvo_background_header.jpg"),
  HomeChef: require("./home/home_chef.png"),
  HomeDelivery: require("./home/home_delivery.png"),
  HomePrivate: require("./home/home_private.png")
};

export default images;
