import styled from "styled-components";
import { Text, Button } from "style/global.styled";

export const Container = styled.div`
  margin: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  margin: 30px auto;
  ${Button} {
    min-width: 100%;
  }
  @media (min-width: 600px) {
    max-width: 250px;
  }
`;

export const Actual = styled(Text)`
  font-size: 18px;
  font-weight: bold;
  margin: 20px 0 10px;
`;
