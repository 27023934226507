import React, { useState, useContext } from "react";
import { SubTitle, Form } from "style/global.styled";
import { UserContext } from "contexts/UserContext";
import { useTranslation } from "react-i18next";
import PersonalInfoGroup from "components/input-groups/PersonalInfoGroup";
import User from "models/user.model";
import PasswordChange from "./password-change";
import useFormValues from "hooks/useFormValues";
import { LoaderButton } from "components/utils";

const PersonalInfo = () => {
  const { t: translate } = useTranslation();
  const {
    state: { user, loading },
    update
  } = useContext(UserContext);
  const [updating, setUpdating] = useState(false);

  const [emailError, setEmailError] = useState("");
  const [values, handleChange] = useFormValues(user);

  if (loading) return null;

  const handleSubmit = e => {
    e.preventDefault();
    setUpdating(true);
    const user = new User(values);
    update(user, "data").finally(() => setUpdating(false));
  };

  return (
    <>
      <SubTitle>{translate("personal.myData")}</SubTitle>
      <Form onSubmit={handleSubmit}>
        <PersonalInfoGroup
          nameProps={{
            value: values.name,
            onChange: handleChange
          }}
          emailProps={{
            value: values.email,
            readOnly: true,
            onFocus: () => setEmailError("immutable.email"),
            onBlur: () => setEmailError(""),
            error: emailError && translate(`error.${emailError}`)
          }}
          phoneProps={{
            value: values.phone,
            onChange: handleChange
          }}
        />
        <LoaderButton
          type="submit"
          loading={updating}
          text={translate("edit")}
        />
      </Form>
      <PasswordChange />
    </>
  );
};

export default PersonalInfo;
