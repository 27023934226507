import React from "react";
import { TextField } from "components/utils";
import { useTranslation } from "react-i18next";

const ProductDetails = ({ values, onChange }) => {
  const { t: translate } = useTranslation();
  const { name, nameEnglish, description, descriptionEnglish } = values;
  const labels = translate("products.details", { returnObjects: true });

  return (
    <>
      <TextField
        name="name"
        label={labels.name}
        value={name}
        onChange={onChange}
        required
      />
      <TextField
        name="nameEnglish"
        label={labels.nameEnglish}
        value={nameEnglish || ""}
        onChange={onChange}
        required
      />
      <TextField
        name="description"
        type="textarea"
        label={labels.description}
        value={description}
        onChange={onChange}
      />
      <TextField
        name="descriptionEnglish"
        type="textarea"
        label={labels.descriptionEnglish}
        value={descriptionEnglish || ""}
        onChange={onChange}
      />
    </>
  );
};

export default ProductDetails;
