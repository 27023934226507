import { useEffect } from 'react';

const useScroll = callback => {
	useEffect(() => {
		window.addEventListener('scroll', callback);
		return () => {
			window.removeEventListener('scroll', callback);
		};
	});
};

export default useScroll;
