import React from "react";
import useWindowSize from "hooks/useWindowSize";
import { TableRow, EqualTableColumn } from "style/global.styled";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const ResponsiveRow = styled(TableRow)`
  ${EqualTableColumn} {
    width: 100%;
    margin: 10px 0;
    justify-content: space-between;
    header {
      font-weight: bold;
    }
    button {
      width: 100%;
    }
  }
`;

const DailyRow = ({
  title,
  ordersNum,
  notPaidOrdersNum,
  notPaidPrice,
  price,
  bold
}) => {
  const { t: translate } = useTranslation();

  const headers = translate("dashboard.dailySummary.headers", {
    returnObjects: true
  });

  const columns = [
    title,
    ordersNum,
    notPaidOrdersNum,
    `${notPaidPrice} ${translate("forint")}`,
    `${price} ${translate("forint")}`
  ];

  const { width } = useWindowSize();

  return width > 1000 ? (
    <TableRow bold={bold}>
      {columns.map((column, index) => (
        <EqualTableColumn key={index} totalColumn={columns.length}>
          {column}
        </EqualTableColumn>
      ))}
    </TableRow>
  ) : (
    <ResponsiveRow bold={bold}>
      {columns.map((column, index) => (
        <EqualTableColumn key={index} totalColumn={columns.length}>
          {index < headers.length && <header>{headers[index].text}</header>}
          {column}
        </EqualTableColumn>
      ))}
    </ResponsiveRow>
  );
};

export default DailyRow;
