import React, { forwardRef } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  color: ${props => props.theme.colors.secondary};
  font-size: 18px;
  letter-spacing: 1px;
  cursor: default;
  input {
    min-width: 20px;
    min-height: 20px;
  }
  span {
    margin: 2px;
  }
`;

const Checkbox = forwardRef(({ label, required, value, ...props }, ref) => {
  return (
    <Wrapper>
      <input
        type="checkbox"
        ref={ref}
        required={required}
        checked={value}
        {...props}
      />
      <span>
        {label}
        {required && "*"}
      </span>
    </Wrapper>
  );
});

export default Checkbox;
