import React from "react";
import { TableHead } from "style/global.styled";
import HeadColumn from "./head-column";
import styled from "styled-components";
import SortSelector from "./sort-selector";

const CustomTableHead = styled(TableHead)`
  @media (max-width: 1000px) {
    display: none;
  }
`;

const SorterTableHead = ({ headers = [], isProfile }) => {
  return (
    <>
      <CustomTableHead>
        {headers.map(({ text, type, hideOnProfile }, index) => {
          if (!isProfile || !hideOnProfile)
            return (
              <HeadColumn
                key={index}
                text={text}
                sortBy={type}
                totalColumn={headers.length}
              />
            );
          return null;
        })}
      </CustomTableHead>
      <SortSelector headers={headers} />
    </>
  );
};

export default SorterTableHead;
