import React from "react";
import Selector from "components/utils/selector";
import useQueryParams from "hooks/useQueryParams";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const CustomSelector = styled(Selector)`
  select {
    text-transform: capitalize;
  }
  @media (min-width: 1000px) {
    display: none;
  }
`;

const SortSelector = ({ headers }) => {
  const {
    t: translate,
    i18n: { language }
  } = useTranslation();

  const [{ sort = "date", desc = true }, set] = useQueryParams();
  const [value, setValue] = useState(JSON.stringify({ sort, desc }));

  const setParams = (sort, desc) => {
    set("sort", sort);
    set("desc", desc);
  };

  const generateOptions = (desc = true) =>
    headers.map(
      ({ text, type }, index) =>
        index < headers.length - 1 && (
          <option key={index} value={JSON.stringify({ sort: type, desc })}>
            {language === "hu"
              ? `${text} ${translate("by")} ${
                  desc ? translate("desc") : translate("asc")
                }`
              : `${desc ? translate("desc") : translate("asc")} ${translate(
                  "by"
                )} ${text}`}
          </option>
        )
    );

  const handleChange = e => {
    const { value } = e.target;
    const { sort, desc } = JSON.parse(value);
    setParams(sort, desc);
    setValue(value);
  };

  if (headers.every(({ type }) => !type)) return null;

  return (
    <CustomSelector value={value} onChange={handleChange}>
      {generateOptions(true)}
      {generateOptions(false)}
    </CustomSelector>
  );
};

export default SortSelector;
