import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Lang = styled.div`
  cursor: pointer;
  transition: color 0.3s;
  :hover {
    color: ${props => props.theme.colors.orange};
  }
`;

const Divider = styled.div`
  width: 1px;
  margin: 0 10px;
  padding: 10px 0;
  background: ${props => props.theme.colors.secondary};
`;

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = e => {
    const lang = e.currentTarget.getAttribute("name");
    i18n.changeLanguage(lang);
  };

  return (
    <Wrapper>
      <Lang name="hu" onClick={changeLanguage}>
        HU
      </Lang>
      <Divider />
      <Lang name="en" onClick={changeLanguage}>
        EN
      </Lang>
    </Wrapper>
  );
};

export default LanguageSelector;
