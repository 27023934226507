import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { CartContext } from "contexts/CartContext";
import { useEffect } from "react";

const SuccessCardPayment = () => {
  const { successCardPayment } = useContext(CartContext);
  useEffect(() => () => successCardPayment());

  return <Redirect to="/" />;
};

export default SuccessCardPayment;
