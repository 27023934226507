import React, { useContext } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { UserContext } from "contexts/UserContext";

const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px;
  letter-spacing: 1px;
  font-size: 25px;
  color: ${props => props.theme.colors.secondary};
  border-top: ${props =>
    props.isFirst && `1px solid ${props.theme.colors.secondary}`};
  border-bottom: 1px solid ${props => props.theme.colors.secondary};
`;

const Details = styled.div`
  display: flex;
  font-family: OpenSansCond-Bold;
  cursor: default;
  & > * {
    margin: 5px;
  }
  @media (max-width: 700px) {
    flex-direction: column;
    font-size: 20px;
  }
`;

const Button = styled.div`
  cursor: pointer;
  margin: 10px;
  font-size: 20px;
  text-align: center;
  transition: color 0.3s;
  :hover {
    color: ${props => props.theme.colors.lightBrown};
  }
  @media (max-width: 700px) {
    font-size: 15px;
  }
`;

const AddressCard = ({ id, city, street, zipCode, isFirst }) => {
  const { removeAddress } = useContext(UserContext);
  const { t: translate } = useTranslation();

  const remove = () => {
    removeAddress(id);
  };

  return (
    <Card isFirst={isFirst}>
      <Details>
        <div>{zipCode},</div>
        <div>{city},</div>
        <div>{street}</div>
      </Details>
      <Button to="#" onClick={remove}>
        {translate("delete")}
      </Button>
    </Card>
  );
};

export default AddressCard;
