import React from "react";
import { Selector } from "components/utils";
import { useTranslation } from "react-i18next";
import { PRODUCT_GROUP } from "utils/contants";

const GroupSelector = ({ value, type, onChange }) => {
  const { t: translate } = useTranslation();

  const groups =
    type && translate(`${type}.categories`, { returnObjects: true });

  return (
    <Selector
      name="group"
      label={translate("chooseGroup")}
      onChange={onChange}
      value={value}
      required
    >
      {groups ? (
        groups.map(({ name, group }, index) => (
          <option key={index} value={PRODUCT_GROUP[group]}>
            {name}
          </option>
        ))
      ) : (
        <option disabled>{translate("chooseCategoryFirst")}</option>
      )}
    </Selector>
  );
};

export default GroupSelector;
