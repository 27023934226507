import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams, Redirect } from "react-router-dom";
import { UserContext } from "contexts/UserContext";
import { Button } from "style/global.styled";
import AddressCard from "./address";
import AddAddress from "./add-address";
import styled from "styled-components";
import useRedirect from "hooks/useRedirect";

const AddButton = styled(Button)`
  margin-top: 45px;
`;

const Addresses = () => {
  const redirect = useRedirect();
  const { t: translate } = useTranslation();
  const { add } = useParams();
  const {
    state: {
      user: { addresses }
    }
  } = useContext(UserContext);

  if (add && add !== "new") return <Redirect to="/profile/addresses" />;

  return (
    <>
      {addresses.map((address, index) => (
        <AddressCard key={index} isFirst={index === 0} {...address} />
      ))}
      <AddButton onClick={() => redirect("/profile/addresses/new")}>
        {translate("addNewAddress")}
      </AddButton>
      {add && <AddAddress />}
    </>
  );
};

export default Addresses;
