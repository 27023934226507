import React, { useContext } from "react";
import { Route } from "react-router-dom";
import { UserContext } from "contexts/UserContext";
import { Wrapper } from "style/global.styled";
import Loader from "../loader";

const PublicRoute = ({ ...props }) => {
  const {
    state: { loading }
  } = useContext(UserContext);

  if (loading)
    return (
      <Wrapper>
        <Loader size="70px" />
      </Wrapper>
    );

  return <Route {...props} />;
};

export default PublicRoute;
