import React from "react";
import { TextField } from "components/utils";
import { useState } from "react";
import { Wrapper, Actual } from "../styles";
import { Text, Button } from "style/global.styled";
import { useEffect } from "react";
import { getMinOrderValue, setMinOrderValue } from "utils/dataHandler";
import { useTranslation } from "react-i18next";

const MinOrder = () => {
  const { t: translate } = useTranslation();

  const [value, setValue] = useState("");
  const [actualPrice, setActualPrice] = useState(0);

  useEffect(() => {
    getMinOrderValue().then(value => setActualPrice(value));
  }, []);

  const handleChange = ({ target }) => {
    setValue(target.value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setMinOrderValue(value).then(price => {
      setActualPrice(price);
      setValue("");
    });
  };

  return (
    <Wrapper>
      <Text>{translate("dashboard.orderInfo.minOrderTitle")}</Text>
      <Actual>
        {translate("dashboard.orderInfo.actualPrice", {
          price: actualPrice
        })}
      </Actual>
      <form onSubmit={handleSubmit}>
        <TextField
          name="min-order"
          type="number"
          label={translate("dashboard.orderInfo.minOrderLabel")}
          value={value}
          onChange={handleChange}
          required
        />
        <Button>{translate("edit")}</Button>
      </form>
    </Wrapper>
  );
};

export default MinOrder;
