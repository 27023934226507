const orderReducer = (state, action) => {
  switch (action.type) {
    case "SET_ORDERS":
      return { ...state, ...action.payload, loading: false };
    case "ADD_ORDERS":
      const newOrder = action.payload;
      const currentOrders = state.today.current;
      return {
        ...state,
        today: { ...state.today, current: [newOrder, ...currentOrders] }
      };
    case "UPDATE_TODAY_ORDER":
      const { to, order } = action.payload;
      const newTodayOrders = Object.keys(state.today).reduce((orders, key) => {
        orders[key] = orders[key].filter(o => o.id !== order.id);
        key === to && orders[key].push(order);
        return orders;
      }, state.today);
      return {
        ...state,
        today: newTodayOrders
      };
    case "CLEAN_ORDERS":
      return {
        all: [],
        today: { current: [], done: [] },
        count: 0,
        ordersByDate: [],
        loading: true
      };
    default:
      return state;
  }
};

export default orderReducer;
