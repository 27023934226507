import { useEffect } from 'react';
import ReactGA from 'react-ga4'

const TRACKING_ID = "G-9EZHVVGFKT"

const InitAnalytics = ({ children }) => {

  useEffect(() => {
      ReactGA.initialize(TRACKING_ID);
  }, []);

  return children
}

export default InitAnalytics;