import React from "react";
import styled from "styled-components";
import { SlideContainer } from "style/global.styled";
import { DetailRow } from "components/utils/table";
import { useTranslation } from "react-i18next";

const Details = styled(SlideContainer)`
  width: 90%;
  max-height: ${({ open }) => (open ? "300px" : 0)};
  transition: ${({ open }) =>
    !open ? "max-height 0.5s, margin 0.5s 0.3s" : "max-height 0.5s"};
  @media (max-width: 1000px) {
    width: 100%;
    padding: 0;
    margin: 0;
  }
`;

const UserDetails = ({ phone, addresses, open }) => {
  const { t: translate } = useTranslation();

  return (
    <Details open={open}>
      <DetailRow
        title={`${translate("dashboard.users.phone")}:`}
        text={phone}
        boldTitle
      />
      <DetailRow
        title={`${translate("dashboard.users.addresses")}:`}
        boldTitle
      />
      {addresses.map(({ city, street, zipCode }, index) => (
        <DetailRow key={index} text={`${zipCode}, ${city}, ${street}`} />
      ))}
    </Details>
  );
};

export default UserDetails;
