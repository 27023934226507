import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { CartContext } from "contexts/CartContext";

const Icon = styled.div`
  font-size: 36px;
  position: relative;
  span {
    position: absolute;
    top: -3px;
    right: -10px;
    width: 26px;
    height: 26px;
    font-size: 16px;
    font-family: OpenSansCond-Bold;
    border-radius: 50%;
    border: 2px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => props.theme.colors.primary};
  }
`;

const CartIcon = () => {
  const {
    cart: { products }
  } = useContext(CartContext);

  return (
    <Icon>
      <FontAwesomeIcon icon={faShoppingCart} />
      <span>{products.length}</span>
    </Icon>
  );
};

export default CartIcon;
