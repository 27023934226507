import React, { useEffect } from "react";
import styled, { keyframes, css } from "styled-components";
import { IconContainer } from "style/global.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const slideIn = keyframes`
  from {transform: translateX(400px)}
  to {transform: translateX(0)}
`;

const Container = styled.div`
  position: relative;
  margin: 10px;
  max-width: 450px;
  animation: ${slideIn} 800ms;
  z-index: 1200;
  color: ${props =>
    props.type === "primary"
      ? props.theme.colors.primary
      : props.theme.colors.light};
  border-radius: 6px;
  background: ${props => selectBackground(props.theme.colors, props.type)};
  box-shadow: 3px 3px 10px ${props => props.theme.colors.shadow};
`;

const Icon = styled(IconContainer)`
  position: absolute;
  top: 0;
  right: 0;
  margin: 6px;
  cursor: pointer;
`;

const Content = styled.div`
  min-width: 300px;
  font-size: 20px;
  font-weight: bold;
  padding: 20px 40px 20px 20px;
  border-radius: 6px;
  @media (max-width: 500px) {
    min-width: unset;
  }
`;

const Text = styled.div`
  ${({ isTitle }) =>
    isTitle &&
    css`
      margin-top: 10px;
      font-size: 18px;
      font-weight: normal;
    `}
`;

const selectBackground = (colors, type) => {
  if (!type) return colors.secondary;
  if (type === "success") return colors.success;
  if (type === "error") return colors.error;
  if (type === "warning") return colors.warning;
  return colors[type];
};

const Notification = ({ id, type, title, text, timeout, onHide }) => {
  useEffect(() => {
    if (timeout) {
      setTimeout(() => {
        onHide(id);
      }, timeout);
    }
  });

  return (
    <Container type={type}>
      <Icon onClick={() => onHide(id)}>
        <FontAwesomeIcon icon={faTimes} />
      </Icon>
      <Content>
        <div>{title}</div>
        <Text isTitle={!!title}>{text}</Text>
      </Content>
    </Container>
  );
};

export default Notification;
