import { useEffect } from "react";
import { useState } from "react";

const useWindowSize = () => {
  const { innerWidth, innerHeight } = window;
  const [size, setSize] = useState({ width: innerWidth, height: innerHeight });

  useEffect(() => {
    window.addEventListener("resize", handleSizeChange);
    return () => window.removeEventListener("resize", handleSizeChange);
  });

  const handleSizeChange = () => {
    const { innerWidth, innerHeight } = window;
    setSize({ width: innerWidth, height: innerHeight });
  };

  return size;
};

export default useWindowSize;
