import React from "react";
import { useTranslation } from "react-i18next";
import { CitySelector, ZipCodeSelector } from "components/utils";
import StreetField from "components/utils/street-field";

const AddressGroup = ({ className, cityProps, streetProps, zipCodeProps }) => {
  const { t: translate } = useTranslation();
  return (
    <div className={className}>
      <ZipCodeSelector
        name="zipCode"
        label={translate("form.zipcode")}
        required
        {...zipCodeProps}
      />
      <CitySelector
        name="city"
        label={translate("form.city")}
        required
        {...cityProps}
      />
      <StreetField
        name="street"
        required
        zipCode={zipCodeProps.value}
        {...streetProps}
      />
    </div>
  );
};

export default AddressGroup;
