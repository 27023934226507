import React from 'react'
import { useTranslation } from "react-i18next";
import { Container } from "style/global.styled";
import styled from "styled-components";

const Cont = styled(Container)`
  min-height: 70vh;
  display: flex;
  position: relative;
  flex-direction: row;
  padding: 50px 40px;
  @media (max-width: 1000px) {
  }
  @media (max-width: 900px) {
    flex-direction: column;
    padding: 20px 15px;
  }
`;

function Menu() {

    const { t: translate } = useTranslation();

  return (
      <Cont>
        <iframe src= {translate("menuPdf")}  width="100%" height="700px"></iframe>
      </Cont>
  )
}

export default Menu