import React, { useEffect } from "react";
import styled from "styled-components";
import { IconContainer } from "style/global.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import useKeyPress from "hooks/useKeyPress";

const Container = styled.div`
  position: fixed;
  display: ${props => (props.open ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  z-index: 2000;
  top: 0;
  left: 0;
  background: #00000098;
`;

const Button = styled(IconContainer)`
  opacity: ${props => props.hide && 0};
  pointer-events: ${props => props.hide && "none"};
  color: ${props => props.theme.colors.light};
  font-size: 40px;
  margin: 0 30px;
  cursor: pointer;
  svg {
    filter: drop-shadow(2px 2px 2px #00000090);
  }
  @media (max-width: 768px) {
    margin: 0 5px;
  }
`;

const Close = styled(Button)`
  font-size: 30px;
  margin: 20px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
`;

const Buttons = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: space-between;
  z-index: 2;
`;

const Img = styled.img`
  width: 60%;
  max-height: calc(100vh - 80px);
  z-index: 1;
  @media (max-width: 768px) {
    width: 100%;
    max-height: 100vh;
  }
`;

const ClickAway = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
`;

const ImageViewer = ({
  images = [],
  open = true,
  current = 0,
  setNext,
  setPrev,
  onClose
}) => {
  const lastIndex = images.length - 1;

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
      return () => {
        document.body.style.overflow = "auto";
      };
    }
  }, [open]);

  useKeyPress(key => {
    if (open) {
      if (key === "ArrowLeft" && current > 0) setPrev();
      else if (key === "ArrowRight" && current < lastIndex) setNext();
    }
  });

  return (
    <Container open={open}>
      <ClickAway onClick={onClose} />
      <Close onClick={onClose}>
        <FontAwesomeIcon icon={faTimes} />
      </Close>
      <Buttons>
        <Button hide={current === 0} onClick={setPrev}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </Button>
        <Button hide={current === lastIndex} onClick={setNext}>
          <FontAwesomeIcon icon={faChevronRight} />
        </Button>
      </Buttons>
      <Img src={images[current]} alt="img" />
    </Container>
  );
};

export default ImageViewer;
