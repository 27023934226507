import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import bg from "assets/images/backgrounds/fanyuvo_background.jpg";
import images from "assets/images";
import { useTranslation } from "react-i18next";

const HomePage = styled.div`
  min-height: calc(100vh - 80px);
  background-image: url(${bg});
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 360px;
`;

const Logo = styled.img`
  margin: 60px 0 30px;
  @media (max-width: 1200px) {
    display: none;
  }
`;

const AboutCards = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
  @media (max-width: 1200px) {
    flex-direction: column;
    margin: 30px 0;
  }
`;

const AboutCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 30px;
  @media (max-width: 1200px) {
    margin: 0 0 20px;
  }
`;

const AboutBody = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 325px;
  height: 325px;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  :hover {
    transform: rotateY(180deg);
  }
`;

const AboutIcon = styled.img`
  backface-visibility: hidden;
`;

const AboutTitle = styled.div`
  max-width: 350px;
  font-size: 40px;
  font-family: Mogra;
  color: ${p => p.theme.colors.secondary};
  text-transform: uppercase;
  text-align: center;
  margin-top: 10px;
`;

const AboutText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  padding: 22px;
  font-size: 24px;
  font-family: OpenSansCond;
  color: ${p => p.theme.colors.secondary};
  letter-spacing: 1px;
  background-color: ${p => p.theme.colors.beigeBg};
  box-shadow: 2px 2px 20px ${p => p.theme.colors.darkerSecodnary};
  transform: rotateY(180deg);
  backface-visibility: hidden;
`;

function Home() {
  const { t: translate } = useTranslation();

  const cards = translate("home.aboutCards", { returnObjects: true });

  return (
    <HomePage>
      <Logo src={translate("logoImgBig")} />
      <AboutCards>
        {cards.map(card => (
          <AboutCard key={card.icon}>
            <AboutBody to={card.link}>
              <AboutIcon src={images[`Home${card.icon}`]} alt={card.icon} />
              <AboutText>{card.text}</AboutText>
            </AboutBody>
            <AboutTitle>{card.title}</AboutTitle>
          </AboutCard>
        ))}
      </AboutCards>
    </HomePage>
  );
}

export default Home;
