const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const lowerCase = /[a-z]/;
const upperCase = /[A-Z]/;
const number = /[0-9]/;

const onlyNumber = /^[0-9]*$/;
const letterAndSpace = /^[a-zA-Z\u00C0-\u017F\s]*$/;

var cardTypeReg = [
  { regEx: /^4[0-9]{5}/gi, cardType: "VISA" },
  { regEx: /^5[1-5][0-9]{4}/gi, cardType: "MASTERCARD" }
];

export const isEmailValid = email => {
  return emailPattern.test(email);
};

export const isPasswordValid = password => {
  return (
    lowerCase.test(password) &&
    upperCase.test(password) &&
    number.test(password) &&
    password.length >= 8
  );
};

export const isPasswordMatch = (password, confirmPassword) => {
  return password === confirmPassword;
};

export const isValidPhoneNumber = value => {
  return !lowerCase.test(value) && !upperCase.test(value);
};

export const isValidNumber = value => {
  return onlyNumber.test(value);
};

export const isValidName = value => {
  return letterAndSpace.test(value);
};

export const isValidCardDate = (month, year) => {
  const actDate = new Date();
  const actYear = actDate.getFullYear();
  const actMonth = actDate.getMonth() + 1;
  const formattedYear = 2000 + parseInt(year);
  if (formattedYear > actYear && month >= 1 && month <= 12) return true;
  if (formattedYear === actYear && month >= actMonth && month <= 12)
    return true;
  return false;
};

export const checkCardType = cardNumber => {
  for (let type of cardTypeReg) {
    const { regEx, cardType } = type;
    if (cardNumber.match(regEx)) return cardType;
  }
  return null;
};
