import _ from "lodash";
import { ORDER_STATE } from "./contants";

export const createExpireDate = toAdd => {
  const { year = 0, month = 0, day = 0, hour = 0, min = 0 } = toAdd;
  const date = new Date();

  date.setFullYear(date.getFullYear() + year);
  date.setMonth(date.getMonth() + month);
  date.setDate(date.getDate() + day);
  date.setHours(date.getHours() + hour);
  date.setMinutes(date.getMinutes() + min);

  return date;
};

export const getProductsCount = (products = []) => {
  let result = [];
  products.forEach(product => {
    if (_.find(result, { product })) {
      result = result.map(p =>
        _.isEqual(p.product, product) ? { ...p, quantity: p.quantity + 1 } : p
      );
    } else result.push({ product, quantity: 1 });
  });
  return result;
};

export const sortOrders = (objectArray = [], config = {}) => {
  const states = Object.values(ORDER_STATE);
  const defaultKey = "orderDate";
  const key = config.key || defaultKey;
  const desc = !!config.desc ? -1 : 1;
  return objectArray.sort((a, b) => {
    if (a[key] === b[key]) return a[defaultKey] < b[defaultKey] ? -1 : 1;
    else if (key === "state")
      return desc * (states.indexOf(a[key]) < states.indexOf(b[key]) ? -1 : 1);
    else return desc * (a[key] < b[key] ? -1 : 1);
  });
};

export const removeAllLetterAndSpace = value => {
  return value.replace(/[^0-9]/g, "");
};
