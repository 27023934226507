import React from "react";
import styled from "styled-components";
import { Selector } from "components/utils";
import { useTranslation } from "react-i18next";
import { ORDER_STATE } from "utils/contants";

const CustomSelector = styled(Selector)`
  padding: 0;
  width: 70%;
  min-width: 140px;
  select {
    height: 40px;
    text-align-last: center;
  }
  option {
    text-align: center;
  }
`;

const StateSelector = ({ defaultValue, onChange }) => {
  const { t: translate } = useTranslation();
  const states = Object.values(ORDER_STATE);

  const handleChange = e => {
    const { name, value } = e.target;
    onChange(name, value);
  };

  return (
    <CustomSelector
      name="state"
      defaultValue={defaultValue}
      onChange={handleChange}
    >
      {states.map((state, index) => (
        <option key={index} value={state}>
          {translate(`orders.states.${state}`)}
        </option>
      ))}
    </CustomSelector>
  );
};

export default StateSelector;
