import { useEffect } from 'react';
import PropTypes from 'prop-types'
import ReactGA from 'react-ga4'
import { Route, useLocation } from 'react-router-dom';

const RouteMiddleware = ({ path, component, title, ...rest }) => {
    const location = useLocation();

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "path", title: "Test" });
    }, [location]);

    return <Route
        path={path}
        component={component}
    />
}

RouteMiddleware.propTypes = {
    path: PropTypes.string.isRequired,
    component: PropTypes.any.isRequired,
    title: PropTypes.string.isRequired
}

export default RouteMiddleware;