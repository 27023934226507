import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import hu from "resources/hu";
import en from "resources/en";
import moment from "moment";

const resources = {
  hu,
  en
};

i18n.on("languageChanged", lng => {
  moment.locale(lng);
});

i18n.use(initReactI18next).init({
  resources,
  lng: "hu",
  fallbackLng: ["hu", "en"],

  interpolation: {
    escapeValue: false
  }
});

export default i18n;
