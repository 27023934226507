import User from "./user.model";
import Address from "./address.model";
import moment from "moment";

function Order({
  user = null,
  name = null,
  email = null,
  phone = null,
  address = null,
  products,
  coupon = null,
  note = null,
  payment,
  encryptedCardData = null,
  preOrderDate = null
}) {
  this.user = user;
  this.name = name;
  this.email = email;
  this.phone = phone;
  this.address = address;
  this.products = products;
  this.coupon = coupon;
  this.note = note;
  this.payment = payment;
  this.encryptedCardData = encryptedCardData;
  this.preOrderDate = preOrderDate;
}

Order.prototype.setUser = function ({ id, ...props }) {
  if (id) {
    this.user = new User({ id, ...props });
  } else {
    const { name, email, phone } = props;
    this.name = name;
    this.email = email;
    this.phone = phone;
  }
};

Order.prototype.setAddress = function ({ city, street, zipCode }) {
  this.address = new Address({ city, street, zipCode });
};

Order.prototype.setCardData = function (encryptedCardData) {
  this.encryptedCardData = encryptedCardData;
};

Order.prototype.setPreOrderDate = function ({ date, time }) {
  if (time !== "now") {
    const formattedDate = moment(new Date(date)).format("YYYY-MM-DD");
    this.preOrderDate = moment(new Date(`${formattedDate} ${time}`)).format(
      "YYYY-MM-DD HH:mm:ss"
    );
  }
};

export default Order;
