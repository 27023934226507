import React from "react";
import useFormValues from "hooks/useFormValues";
import { addNewProduct } from "utils/dataHandler";
import { useContext } from "react";
import { NotificationContext } from "contexts/NotificationContext";
import { useState } from "react";
import ProductForm from "../product-from";

const ProductAddition = () => {
  const { notificationModels } = useContext(NotificationContext);

  const initValues = {
    name: "",
    nameEnglish: "",
    description: "",
    descriptionEnglish: "",
    type: "",
    group: "",
    allergenics: [],
    price: "",
    orderable: "",
    loquuId: ""
  };

  const [adding, setAdding] = useState(false);
  const [values, handleChange, setValues] = useFormValues(initValues);

  const handleAllergensChange = value => {
    setValues({ ...values, allergenics: value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setAdding(true);
    addNewProduct(values)
      .then(() => {
        notificationModels.successAdded();
        setValues(initValues);
      })
      .catch(err => console.log(err))
      .finally(() => setAdding(false));
  };

  return (
    <ProductForm
      values={values}
      handleChange={handleChange}
      allergenChange={handleAllergensChange}
      onSubmit={handleSubmit}
      loading={adding}
    />
  );
};

export default ProductAddition;
