import React, { useContext, useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

import { Button } from "style/global.styled";
import { CartContext } from "contexts/CartContext";
import { getMinOrderValue } from "utils/dataHandler";
import useRedirect from "hooks/useRedirect";

const Wrapper = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 50px 0 auto auto;
  padding: 15px;
  color: ${props => props.theme.colors.primary};
  background: ${props => props.theme.colors.secondary};
  letter-spacing: 2px;
  @media (max-width: 768px) {
    max-width: unset;
  }
`;

const CotinueBtn = styled(Button)`
  width: 100%;
  margin: 20px 0 10px;
  color: ${props => props.theme.colors.secondary};
  background: ${props => props.theme.colors.primary};
`;

const LinkButton = styled(Link)`
  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      ${CotinueBtn} {
        color: ${props => props.theme.colors.transparentPrimary};
        background: ${props => props.theme.colors.transparentPrimary};
      }
    `};
`;

const Price = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: right;
  margin: 0 10px;
  padding: 10px 0;
  font-size: 18px;
  border-bottom: 1px solid ${props => props.theme.colors.transparentPrimary};
  span {
    width: 50%;
    text-align: left;
    font-weight: bold;
  }
  :nth-child(4) {
    border: 0;
    font-size: 25px;
  }
  @media (max-width: 500px) {
    font-size: 15px;
    :nth-child(4) {
      font-size: 20px;
    }
  }
`;

const MinOrderText = styled.div`
  font-size: 18px;
  font-style: italic;
  font-weight: bold;
  margin: 0 10px;
  color: ${props => props.theme.colors.lighterOrange};
`;

const TotalPrice = ({ className, address }) => {
  const { t: translate } = useTranslation();
  const { pathname } = useLocation();
  const redirect = useRedirect();

  const {
    getPrices,
    cart: { products, coupon }
  } = useContext(CartContext);

  const init = {
    originalPrice: 0,
    discountedPrice: 0,
    shippingCost: 0,
    total: 0,
    minOrderPrice: 1
  };
  const [
    { originalPrice, discountedPrice, shippingCost, total, minOrderPrice },
    setPrices
  ] = useState(init);

  const [isOrderable, setIsOrderable] = useState(false);

  useEffect(() => {
    axios
      .all([getPrices({ products, coupon, address }), getMinOrderValue()])
      .then(([prices, minOrderPrice]) => {
        const { originalPrice, discountedPrice, shippingCost, total } = prices;
        if (minOrderPrice > total && pathname === "/pay") {
          return redirect("/cart");
        } else {
          setIsOrderable(minOrderPrice <= total);
          setPrices({
            originalPrice,
            discountedPrice,
            shippingCost,
            total,
            minOrderPrice
          });
        }
      })
      .catch(err => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, coupon, getPrices, address]);

  const priceTypes = ["subtotal", "delivery", "discount", "totalCost"];
  const prices = {
    subtotal: originalPrice,
    delivery: address ? shippingCost : translate("cart.deliveryHelperText"),
    discount: discountedPrice - originalPrice,
    totalCost: total
  };

  return (
    <Wrapper className={className}>
      {priceTypes.map((type, index) => (
        <Price key={index}>
          <span>{translate(`cart.${type}`)}: </span>
          {typeof prices[type] === "string"
            ? prices[type]
            : `${prices[type]} ${translate("forint")}`}
        </Price>
      ))}
      {!isOrderable && (
        <MinOrderText>
          {translate("cart.minOrderText", { price: minOrderPrice })}
        </MinOrderText>
      )}
      <LinkButton to="/pay" disabled={!isOrderable}>
        <CotinueBtn>{translate("cart.goToPayment")}</CotinueBtn>
      </LinkButton>
    </Wrapper>
  );
};

export default TotalPrice;
