import React from "react";
import styled from "styled-components";
import { Login, SignUp } from "components/auth";
import { Container } from "style/global.styled";
import images from "assets/images";

const Section = styled(Container)`
  display: flex;
  justify-content: space-evenly;
  padding: 30px 10px;
  background: url(${images.BackgroundLight});
  background-attachment: fixed;
  & > * {
    width: 450px;
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    & > * {
      width: 80%;
    }
  }
  @media (max-width: 600px) {
    & > * {
      width: 100%;
    }
  }
`;

const Auth = () => {
  return (
    <Section>
      <Login />
      <SignUp />
    </Section>
  );
};

export default Auth;
