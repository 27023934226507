import React, { useState, useEffect } from "react";
import { Wrapper, Actual } from "../styles";
import { Text, Button } from "style/global.styled";
import { TextField } from "components/utils";
import { useTranslation } from "react-i18next";
import { getShippingTime, setShippingTime } from "utils/dataHandler";

const DeliveryTime = () => {
  const { t: translate } = useTranslation();

  const [value, setValue] = useState("");
  const [actualTime, setActualTime] = useState(0);

  useEffect(() => {
    getShippingTime().then(time => setActualTime(time));
  }, []);

  const handleChange = ({ target }) => {
    setValue(target.value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setShippingTime(value).then(time => {
      setActualTime(time);
      setValue("");
    });
  };

  return (
    <Wrapper>
      <Text>{translate("dashboard.orderInfo.deliveryTimeTitle")}</Text>
      <Actual>
        {translate("dashboard.orderInfo.actualTime", {
          time: actualTime
        })}
      </Actual>
      <form onSubmit={handleSubmit}>
        <TextField
          name="delivery-time"
          type="number"
          label={translate("dashboard.orderInfo.deliveryTimeLabel")}
          value={value}
          onChange={handleChange}
          required
        />
        <Button>{translate("edit")}</Button>
      </form>
    </Wrapper>
  );
};

export default DeliveryTime;
