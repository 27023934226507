import React from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Navbar from "./navbar";
import bg from "assets/images/backgrounds/fanyuvo_background.jpg";

const HeaderContainer = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
`;

const HeaderImageWrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  background-image: url(${bg});
  background-attachment: fixed;
  @media (max-width: 1200px) {
    display: none;
  }
`;

const Header = () => {
  const { t: translate } = useTranslation();
  const { pathname } = useLocation();

  const isHomePage = pathname === "/";

  return (
    <HeaderContainer>
      {!isHomePage && (
        <HeaderImageWrapper>
          <img src={translate("logoImg")} alt="Logo" />
        </HeaderImageWrapper>
      )}
      <Navbar />
    </HeaderContainer>
  );
};

export default Header;
