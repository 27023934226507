function User({ id, name, email, phone, password, addresses, role }) {
  this.id = id;
  this.name = name;
  this.email = email;
  this.phone = phone;
  this.password = password;
  this.addresses = addresses;
  this.role = role;
}

export default User;
