import React, { useContext, useState, useMemo } from "react";
import { Container, Button } from "style/global.styled";
import styled from "styled-components";
import useFormValues from "hooks/useFormValues";
import { useTranslation } from "react-i18next";
import { LoaderButton, LegalCheckbox } from "components/utils";
import { UserContext } from "contexts/UserContext";
import { TotalPrice, DeliveryTimeNotification } from "components/cart";
import { ShippingInfo, PaymentMethod } from "components/payment";
import { PAYMENT_METHOD } from "utils/contants";
import _ from "lodash";
import { CartContext } from "contexts/CartContext";
import Order from "models/order.model";
import { Redirect } from "react-router-dom";
import Address from "models/address.model";
import NoteField from "components/payment/note-field";
import ShippingTime from "components/payment/shipping-time";

const Wrapper = styled.div`
  width: 95%;
  margin: 0 auto;
`;

const CustomPrices = styled(TotalPrice)`
  margin-top: 18px;
  max-width: unset;
  background: ${props => props.theme.colors.orange};
  ${Button} {
    display: none;
  }
`;

const OrderButton = styled(LoaderButton)`
  width: 100%;
  margin: 20px auto;
`;

const PricesNoteRow = styled.div`
  @media (min-width: 700px) {
    display: flex;
    align-items: flex-start;
    & > * {
      width: 50%;
      :nth-child(1) {
        margin-right: 20px;
      }
      :nth-child(2) {
        margin-left: 20px;
      }
    }
  }
`;

const Payment = () => {
  const { t: translate } = useTranslation();
  const { CASH } = PAYMENT_METHOD;
  const {
    state: {
      user: { id, name, email, phone }
    }
  } = useContext(UserContext);
  const { cart, submitOrder } = useContext(CartContext);

  const initValues = {
    name: name || "",
    phone: phone || "",
    email: email || "",
    city: "",
    street: "",
    zipCode: "",
    note: "",
    payment: CASH,
    cardNumber: "",
    expMonth: "",
    expYear: "",
    cardHolderName: "",
    cvn: "",
    terms: false,
    preOrderDate: {
      date: new Date(),
      time: "now"
    }
  };

  const [sending, setSending] = useState(false);
  const [values, handleChange, setValues] = useFormValues(initValues);

  const setNote = note => {
    setValues({ ...values, note });
  };

  const setOderDate = ({ name, value }) => {
    setValues({
      ...values,
      preOrderDate: { ...values.preOrderDate, [name]: value }
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const { products, coupon } = cart;
    if (!_.isEmpty(products)) {
      setSending(true);
      const { note, payment } = values;
      const order = new Order({ products, coupon, note, payment });
      order.setUser({ id, ...values });
      order.setAddress(values);
      order.setPreOrderDate(values.preOrderDate);
      submitOrder(order).catch(() => setSending(false));
    }
  };

  const { city, street, zipCode } = values;
  const getAddressIfFilled = useMemo(() => {
    const address = new Address({ city, street, zipCode });
    const isFilled = Object.values(address).every(value => !!value);
    return isFilled ? address : null;
  }, [city, street, zipCode]);

  if (_.isEmpty(cart.products)) return <Redirect to="/" />;

  return (
    <Container>
      <DeliveryTimeNotification />
      <Wrapper>
        <form onSubmit={handleSubmit}>
          <ShippingInfo
            values={values}
            setValues={setValues}
            handleChange={handleChange}
          />
          <PricesNoteRow>
            <NoteField
              value={values.note}
              handleChange={handleChange}
              setNote={setNote}
            />
            <CustomPrices address={getAddressIfFilled} />
          </PricesNoteRow>
          <PaymentMethod values={values} handleChange={handleChange} />
          <ShippingTime value={values.preOrderDate} onChange={setOderDate} />
          <LegalCheckbox value={values.terms} onChange={handleChange} />
          <OrderButton
            type="submit"
            loading={sending}
            text={translate("payment.sendOrder")}
          />
        </form>
      </Wrapper>
    </Container>
  );
};

export default Payment;
