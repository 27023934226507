import React from "react";
import { useTranslation } from "react-i18next";
import ProductRow from "./product-row";
import { TableColumn, TableHead } from "style/global.styled";

const CartTable = ({ products }) => {
  const { t: translate } = useTranslation();

  return (
    <>
      <TableHead>
        {translate("cart.headers", { returnObjects: true }).map(
          (text, index) => (
            <TableColumn key={index}>{text}</TableColumn>
          )
        )}
      </TableHead>
      {products.map((product, index) => (
        <ProductRow key={index} {...product} />
      ))}
    </>
  );
};

export default CartTable;
