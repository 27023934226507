import { useHistory } from "react-router-dom";

const useRedirect = path => {
  const history = useHistory();
  const redirect = path => {
    history.push(path);
  };
  return redirect;
};

export default useRedirect;
