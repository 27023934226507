import React, { useState } from "react";
import { useEffect } from "react";
import { getAllCoupons, addNewCoupon } from "utils/dataHandler";
import { BaseTable } from "components/utils";
import { useTranslation } from "react-i18next";
import CouponRow from "./coupon-row/CouponRow";
import AddCoupon from "./add-coupon";
import { SubTitle } from "style/global.styled";
import styled from "styled-components";

const Wrapper = styled.div`
  margin: 50px 0;
`;

const Title = styled(SubTitle)`
  text-align: center;
  margin: 30px 0;
`;

const Coupons = () => {
  const { t: translate } = useTranslation();
  const initState = { coupons: [], count: 0, loading: true };
  const [state, setState] = useState(initState);
  const { coupons, loading, count } = state;

  useEffect(() => {
    getAllCoupons()
      .then(({ coupons, count }) =>
        setState({ coupons, count, loadign: false })
      )
      .catch(err => console.log(err));
    return () => setState({ users: [], count: 0, loading: true });
  }, []);

  const handleSubmit = coupon => {
    return addNewCoupon(coupon).then(newCoupon =>
      setState({ ...state, coupons: [...coupons, newCoupon] })
    );
  };

  const handleDelete = code => {
    const newCoupons = coupons.filter(coupon => code !== coupon.code);
    setState({ ...state, coupons: newCoupons });
  };

  const { addNew, allCoupon } = translate("dashboard.coupons.titles", {
    returnObjects: true
  });

  return (
    <Wrapper>
      <Title>{addNew}</Title>
      <AddCoupon onSubmit={handleSubmit} />
      <Title>{allCoupon}</Title>
      <BaseTable
        loading={loading}
        headers={translate("dashboard.coupons.headers", {
          returnObjects: true
        })}
        pagination={{ itemsNumber: count }}
      >
        {coupons.map((coupon, index) => (
          <CouponRow key={index} {...coupon} onDelete={handleDelete} />
        ))}
      </BaseTable>
    </Wrapper>
  );
};

export default Coupons;
