import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import { useTranslation } from "react-i18next";

const DatePicker = ({ format, value, onChange, error, minDate }) => {
  const { t: translate } = useTranslation();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        minDate = { minDate }
        disableToolbar
        format={format}
        value={value}
        onChange={onChange}
        helperText={error && translate("error.invalidDate")}
        error={error}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
