import React from "react";
import { Container } from "style/global.styled";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import useScrollTop from "hooks/useScrollTop";
import { ProductCategories, ProductList, Allergens } from "components/products";
import { useTranslation } from "react-i18next";
import ProductContextProvider from "contexts/ProductContext";
import { PRODUCT_TYPES } from "utils/contants";

const Cont = styled(Container)`
  min-height: 70vh;
  display: flex;
  position: relative;
  flex-direction: row;
  padding: 50px 40px;
  @media (max-width: 1000px) {
  }
  @media (max-width: 900px) {
    flex-direction: column;
    padding: 20px 15px;
  }
`;

const SideMenu = styled.div`
  margin: 0 auto;
  @media (max-width: 900px) {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }
`;

const PdfLink = styled.a`
  display: block;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  padding: 5px;
  margin: 60px 15px 80px;
  transition: all 0.3s;
  max-width: 230px;
  color: ${props => props.theme.colors.light};
  background-color: ${props => props.theme.colors.secondary};
  font-family: OpenSansCond-Bold;
  :hover {
    opacity: 0.8;
  }
  @media (max-width: 900px) {
    margin: 0 5px 20px;
    max-width: unset;
    padding: 8px 10px;
  }
  @media (max-width: 600px) {
    flex: 1;
    font-size: 14px;
    padding: 5px 10px;
  }
`;

const Products = ({ location }) => {
  const { t: translate } = useTranslation();

  const type = location.pathname.slice(1, location.pathname.indexOf("/", 1));
  const { group } = useParams();

  useScrollTop(group);

  return (
    <ProductContextProvider>
      <Cont>
        <SideMenu>
          <ProductCategories type={type} group={group} />
          <PdfLink href={translate("menuPdf")} target="blank">
            {translate("downloadableMenu")}
          </PdfLink>
          {type === PRODUCT_TYPES.FOOD && <Allergens />}
        </SideMenu>
        <ProductList type={type} group={group} />
      </Cont>
    </ProductContextProvider>
  );
};

export default Products;
