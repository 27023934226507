import React from "react";
import styled from "styled-components";
import visa from "payment-icons/min/flat/visa.svg";
import mastercard from "payment-icons/min/flat/mastercard.svg";
import { Text } from "style/global.styled";
import { CARD_TYPES } from "utils/contants";
import { useTranslation } from "react-i18next";

const Types = styled.div`
  ${Text} {
    font-size: 18px;
    margin: 10px 0;
  }
  margin: 10px 0;
`;

const Icons = styled.div`
  display: flex;
`;

const Icon = styled.img`
  width: 50px;
  max-height: 32px;
  transition: filter 0.5s ease;
  margin-right: 10px;
  filter: ${({ selected }) => !selected && "grayscale(100%)"};
`;

const CardTypes = ({ type }) => {
  const { t: translate } = useTranslation();
  const { VISA, MASTERCARD } = CARD_TYPES;

  return (
    <Types>
      <Text>{translate("payment.cardTypes")}</Text>
      <Icons>
        <Icon src={visa} alt="visa" selected={type === VISA} />
        <Icon
          src={mastercard}
          alt="mastercard"
          selected={type === MASTERCARD}
        />
      </Icons>
    </Types>
  );
};

export default CardTypes;
