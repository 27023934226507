import React, { createContext, useContext, useRef } from "react";
import { useReducer } from "react";
import orderReducer from "reducers/orderReducer";
import {
  getTodayOrders,
  getAllOrders,
  changeOrderStatus,
  getOrdersByDate
} from "utils/dataHandler";
import { NotificationContext } from "./NotificationContext";
import { useCallback } from "react";
import { ORDER_STATE } from "utils/contants";
import notificationSound from "assets/sounds/swiftly-610.mp3";

const { NEW, PREPARING, DELIVERING, DELIVERED } = ORDER_STATE;
const CURRENT = [NEW, PREPARING];
const DONE = [DELIVERING, DELIVERED];

export const OrderContext = createContext();

const OrderContextProvider = ({ children }) => {
  const { notificationModels } = useContext(NotificationContext);
  const audioRef = useRef(new Audio(notificationSound));

  const init = {
    today: { current: [], done: [] },
    all: [],
    orders: 0,
    loading: true,
    ordersByDate: []
  };

  const summaryInit = {
    ordersNum: 0,
    notPaidOrdersNum: 0,
    notPaidPrice: 0,
    price: 0
  };

  const [state, dispatch] = useReducer(orderReducer, init);

  const separateTodayOrders = useCallback(orders => {
    return orders.reduce(
      (acc, current) => {
        if (CURRENT.includes(current.state)) acc.current.push(current);
        else if (DONE.includes(current.state)) acc.done.push(current);
        return acc;
      },
      { current: [], done: [] }
    );
  }, []);

  const fetchTodayOrders = useCallback(() => {
    getTodayOrders()
      .then(orders => {
        dispatch({
          type: "SET_ORDERS",
          payload: { today: separateTodayOrders(orders) }
        });
      })
      .catch(err => console.log(err));
  }, [separateTodayOrders]);

  const fetchOrdersByDate = useCallback(date => {
    getOrdersByDate(date)
      .then(orders => {
        dispatch({
          type: "SET_ORDERS",
          payload: { ordersByDate: orders }
        });
      })
      .catch(err => console.log(err));
  }, []);

  const fetchAllOrders = useCallback((pageNum, sortConfig) => {
    getAllOrders(pageNum, sortConfig)
      .then(({ orders, count }) =>
        dispatch({ type: "SET_ORDERS", payload: { all: orders, count } })
      )
      .catch(err => console.log(err));
  }, []);

  const cleanUp = useCallback(() => {
    dispatch({ type: "CLEAN_ORDERS" });
  }, []);

  const addOrder = order => {
    notificationModels.newOrderReceived();
    dispatch({ type: "ADD_ORDERS", payload: order });
    audioRef.current.play();
  };

  const modifyOrder = updatedOrder => {
    changeOrderStatus(updatedOrder)
      .then(order => {
        let to = "";
        if (CURRENT.includes(order.state)) to = "current";
        else if (DONE.includes(order.state)) to = "done";
        dispatch({
          type: "UPDATE_TODAY_ORDER",
          payload: { to, order }
        });
        notificationModels.successModify();
      })
      .catch(() => notificationModels.serverError());
  };

  const getOrdersNumber = () => {
    return getTodayOrders()
      .then(orders => getOrdersNumberByState(orders))
      .catch(err => console.log(err));
  };

  const getOrdersNumberByState = orders => {
    return orders.reduce(
      (acc, curr) => {
        const state = curr.state;
        acc[state] += 1;
        return acc;
      },
      { NEW: 0, PREPARING: 0, DELIVERING: 0, DELIVERED: 0 }
    );
  };

  const calculateDailySummary = orders => {
    return orders.reduce(
      (acc, curr) => {
        const key = curr.payment;
        if (curr.payed) {
          acc[key].price += curr.discountedPrice || 0;
          acc.summary.price += curr.discountedPrice || 0;
        } else {
          acc[key].notPaidOrdersNum += 1;
          acc[key].notPaidPrice += curr.discountedPrice || 0;
          acc.summary.notPaidPrice += curr.discountedPrice || 0;
          acc.summary.notPaidOrdersNum += 1;
        }
        acc[key].ordersNum += 1;
        acc.summary.ordersNum += 1;
        return acc;
      },
      {
        CASH: { ...summaryInit },
        CREDIT_CARD_ONLINE: { ...summaryInit },
        CREDIT_CARD: { ...summaryInit },
        RESTAURANT_CASH: { ...summaryInit },
        RESTAURANT_CARD: { ...summaryInit },
        summary: { ...summaryInit }
      }
    );
  };

  return (
    <OrderContext.Provider
      value={{
        state,
        dispatch,
        addOrder,
        cleanUp,
        fetchTodayOrders,
        fetchOrdersByDate,
        fetchAllOrders,
        modifyOrder,
        getOrdersNumber,
        calculateDailySummary
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export default OrderContextProvider;
