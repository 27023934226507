import React from "react";
import { TextField } from "components/utils";
import { useTranslation } from "react-i18next";

const PasswordGroup = ({ className, passwordProps, confrimProps, newPw }) => {
  const { t: translate } = useTranslation();
  return (
    <div className={className}>
      <TextField
        type="password"
        name="password"
        label={
          !newPw ? translate("form.password") : translate("form.newPassword")
        }
        required
        {...passwordProps}
      />
      <TextField
        type="password"
        name="confirmPassword"
        label={translate("form.confirmPassword")}
        required
        {...confrimProps}
      />
    </div>
  );
};

export default PasswordGroup;
