import React from "react";
import { useEffect } from "react";
import ReactDOM from "react-dom";
import {
  Confirm,
  Products,
  Contact,
  Events,
  Profile,
  Auth,
  Home,
  Cart,
  Map,
  Menu,
  Payment,
  Dashboard,
  SuccessCardPayment,
  Reservation
} from "pages";
import ForgotPassword from "pages/ForgotPassword";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { ROLES } from "utils/contants";
import { PrivateRoute, PublicRoute } from "components/utils";
import Layout from "components/layout";

import RouteMiddleware from "components/analytics/RouteMiddlewear";

import "./i18n";
import InitAnalytics from "components/analytics/InitAnalytics";
import ReactGA from 'react-ga4'

const { ADMIN, MANAGER, SHOP_ASSISTANT } = ROLES;

const TRACKING_ID = "G-9EZHVVGFKT"
ReactGA.initialize(TRACKING_ID)

const routes = (
  <Router>
    <InitAnalytics>
      <Layout>
        <Switch>
          <Route
            path="/user/forgot-password/:token?"
            component={ForgotPassword}
          />
          <Route path="/user/confirmation/:token" component={Confirm} />
          <Route
            exact
            path="/delivery"
            render={() => <Redirect to="/foods/snack" />}
          />
          <Route path="/foods/:group" component={Products} />
          <RouteMiddleware path="/menu" component={Menu} title="Menu" />
          <Route
            exact
            path="/drinks"
            render={() => <Redirect to="/drinks/soft-drink" />}
          />
          <Route path="/drinks/:group" component={Products} />
          <Route path="/contact" component={Contact} />
          <Route path="/events" component={Events} />
          <Route path="/map" component={Map} />
          <Route path="/cart" component={Cart} />
          <Route path="/reservation" component={Reservation} />
          <PublicRoute exact path="/pay" component={Payment} />
          <PublicRoute
            path="/pay/card-payment/successful"
            component={SuccessCardPayment}
          />
          <PrivateRoute path="/profile/:subpage?/:add?" component={Profile} />
          <PrivateRoute path="/login" component={Auth} />
          <PrivateRoute
            path="/dashboard/:subpage?"
            component={Dashboard}
            roles={[ADMIN, MANAGER, SHOP_ASSISTANT]}
          />
          <Route exact path="/" component={Home} />
          <Redirect to="/" />
        </Switch>
      </Layout>
    </InitAnalytics>
  </Router>
);

ReactDOM.render(routes, document.getElementById("root"));
