import React, { useEffect, useContext, useState } from "react";
import OrderTable from "components/orders";
import styled from "styled-components";
import useScrollTop from "hooks/useScrollTop";
import { ORDER_SORT_TYPE } from "utils/contants";
import useQueryParams from "hooks/useQueryParams";
import { getUserOrders } from "utils/dataHandler";
import { UserContext } from "contexts/UserContext";

const CustomOrderTable = styled(OrderTable)`
  width: 100%;
  margin: 0 auto;
`;

const Orders = () => {
  const {
    state: {
      user: { id }
    }
  } = useContext(UserContext);

  const [{ page, sort = "date", desc = "true" }] = useQueryParams();
  const currentPage = page > 0 ? page : 1;

  useScrollTop(currentPage);

  const initState = { orders: [], count: 0, loading: true };
  const [{ orders, count, loading }, setState] = useState(initState);

  useEffect(() => {
    const sortConfig = {
      by: ORDER_SORT_TYPE[sort],
      desc: JSON.parse(desc)
    };
    getUserOrders(id, currentPage, sortConfig)
      .then(({ orders, count }) => {
        setState({ orders, count, loading: false });
      })
      .catch(err => console.log(err));
  }, [id, currentPage, sort, desc]);

  return (
    <CustomOrderTable
      loading={loading}
      orders={orders}
      pagination={{ itemsNumber: count }}
      isProfile
    />
  );
};

export default Orders;
