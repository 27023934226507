import { createGlobalStyle } from "styled-components";
import OpenSans from "assets/fonts/OpenSans-Regular.ttf";
import OpenSansCondLight from "assets/fonts/OpenSansCondensed-Light.ttf";
import OpenSansCondItalic from "assets/fonts/OpenSansCondensed-LightItalic.ttf";
import OpenSansCondBold from "assets/fonts/OpenSansCondensed-Bold.ttf";
import Mogra from "assets/fonts/Mogra-Regular.ttf";

export const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: OpenSansCond;
        src: url(${OpenSansCondLight});
    }
    @font-face {
        font-family: OpenSansCond-Bold;
        src: url(${OpenSansCondBold});
    }
    @font-face {
        font-family: OpenSansCond-Italic;
        src: url(${OpenSansCondItalic});
    }
    @font-face {
        font-family: OpenSansCond;
        src: url(${OpenSansCondLight});
    }
    @font-face {
        font-family: OpenSansCond-Bold;
        src: url(${OpenSansCondBold});
    }
    @font-face {
        font-family: OpenSans;
        src: url(${OpenSans});
    }
    @font-face {
        font-family: Mogra;
        src: url(${Mogra});
    }
    html, body {
        margin: 0;
        font-family: OpenSansCond;
        scroll-behavior: smooth;
    }
    * {
        box-sizing: border-box;
    }
    *:focus {
        outline: none;
    }
    a {
        text-decoration: none;
        color: unset;
    }
    input:invalid {
        box-shadow: none;
    }
`;

export const colors = {
  light: "#ffffff",
  transparentWhite: "rgba(255, 255, 255, 0.6)",
  gray: "#f2eded",
  lightGray: "#f7f2f2",
  darkGray: "#b8b4b4",
  beigeBg: "rgba(255, 241, 211, 0.85)",
  primary: "#FDE8C1",
  transparentPrimary: "#f6e2b970",
  lightBrown: "#A78156",
  secondary: "#3C1412",
  darkerSecondary: "#301e12",
  transparentSecondary: "#673F2670",
  orange: "#DE7104",
  lighterOrange: "#eb9244",
  darkerOrange: "#8e480b",
  shadow: "#673F2620",
  error: "#F5533E",
  success: "#4BB543",
  warning: "#ffae42"
};
