import React from "react";
import Selector from "../selector";
import { useTranslation } from "react-i18next";

const CitySelector = ({ ...props }) => {
  const { t: translate } = useTranslation();

  const cities = translate("cities", { returnObjects: true });

  return (
    <Selector {...props}>
      {cities.map(city => (
        <option key={city} value={city}>
          {city}
        </option>
      ))}
    </Selector>
  );
};

export default CitySelector;
