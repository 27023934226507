import React from "react";
import { Selector } from "components/utils";
import { useTranslation } from "react-i18next";

const TypeSelector = ({ value, onChange }) => {
  const { t: translate } = useTranslation();
  const types = translate("products.types", { returnObjects: true });

  return (
    <Selector
      name="type"
      label={translate("chooseCategory")}
      onChange={onChange}
      value={value}
      required
    >
      {Object.keys(types).map((type, index) => (
        <option key={index} value={type}>
          {types[type]}
        </option>
      ))}
    </Selector>
  );
};

export default TypeSelector;
