import React from "react";
import { Selector } from "components/utils";
import { useTranslation } from "react-i18next";
import { ORDERABLE } from "utils/contants";

const OrderableSelector = ({ value, onChange }) => {
  const { t: translate } = useTranslation();

  const options = Object.keys(ORDERABLE);

  return (
    <Selector
      name="orderable"
      label={translate("products.chooseOrderable")}
      onChange={onChange}
      value={value}
      required
    >
      {options.map((option, index) => (
        <option key={index} value={ORDERABLE[option]}>
          {translate(`products.${option}`)}
        </option>
      ))}
    </Selector>
  );
};

export default OrderableSelector;
