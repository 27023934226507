import React from "react";
import { Container } from "style/global.styled";
import { DashboardNav, OrderActivator } from "components/dashboard";
import {
  CurrentOrders,
  DoneOrders,
  AllOrders,
  DailySummary
} from "components/dashboard/orders";
import Users from "components/dashboard/users";
import UserAddition from "components/dashboard/user-addition";
import Coupons from "components/dashboard/coupons";
import ProductAddition from "components/dashboard/product-addition";
import ProductEditor from "components/dashboard/product-editor";
import MinOrder from "components/dashboard/order-info/min-order";
import DeliveryTime from "components/dashboard/order-info/delivery-time";
import EventLog from "components/dashboard/event-log";
import styled from "styled-components";
import { Redirect, Switch } from "react-router-dom";
import { PrivateRoute } from "components/utils";
import { ROLES } from "utils/contants";

const CustomContainer = styled(Container)`
  min-height: 70vh;
`;

const Dashboard = () => {
  const { ADMIN, MANAGER, SHOP_ASSISTANT } = ROLES;

  return (
    <CustomContainer>
      <DashboardNav />
      <OrderActivator />
      <Switch>
        <PrivateRoute
          exact
          path="/dashboard/current-orders"
          component={CurrentOrders}
          roles={[ADMIN, MANAGER, SHOP_ASSISTANT]}
        />
        <PrivateRoute
          exact
          path="/dashboard/done-orders"
          component={DoneOrders}
          roles={[ADMIN, MANAGER, SHOP_ASSISTANT]}
        />
        <PrivateRoute
          exact
          path="/dashboard/daily-summary"
          component={DailySummary}
          roles={[ADMIN, MANAGER, SHOP_ASSISTANT]}
        />
        <PrivateRoute
          exact
          path="/dashboard/all-orders"
          component={AllOrders}
          roles={[ADMIN, MANAGER]}
        />
        <PrivateRoute
          exact
          path="/dashboard/coupons"
          component={Coupons}
          roles={[ADMIN, MANAGER]}
        />
        <PrivateRoute
          exact
          path="/dashboard/add-product"
          component={ProductAddition}
          roles={[ADMIN, MANAGER]}
        />
        <PrivateRoute
          exact
          path="/dashboard/edit-product"
          component={ProductEditor}
          roles={[ADMIN, MANAGER, SHOP_ASSISTANT]}
        />
        <PrivateRoute
          exact
          path="/dashboard/min-order-price"
          component={MinOrder}
          roles={[ADMIN, MANAGER]}
        />
        <PrivateRoute
          exact
          path="/dashboard/delivery-time"
          component={DeliveryTime}
          roles={[ADMIN, MANAGER, SHOP_ASSISTANT]}
        />
        <PrivateRoute
          exact
          path="/dashboard/event-log"
          component={EventLog}
          roles={[ADMIN, MANAGER]}
        />
        <PrivateRoute
          exact
          path="/dashboard/users"
          component={Users}
          roles={[ADMIN]}
        />
        <PrivateRoute
          exact
          path="/dashboard/add-user"
          component={UserAddition}
          roles={[ADMIN, MANAGER, SHOP_ASSISTANT]}
        />
        <Redirect to="/dashboard/current-orders" />
      </Switch>
    </CustomContainer>
  );
};

export default Dashboard;
