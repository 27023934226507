import { useState } from "react";
import { isEmailValid } from "utils/validation";

const useEmailValidation = email => {
  const [emailError, setEmailError] = useState("");

  const checkEmailValidation = () => {
    if (email && !isEmailValid(email)) setEmailError("emailInvalid");
    else setEmailError("");
  };

  return [emailError, checkEmailValidation, setEmailError];
};

export default useEmailValidation;
