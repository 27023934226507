const userReducer = (state, action) => {
  switch (action.type) {
    case "USER_LOGIN":
      return { ...state, isLoggedIn: true, loading: true };
    case "USER_LOGOUT":
      return { user: {}, isLoggedIn: false, loading: false };
    case "SET_USER":
      const { user } = action.payload;
      return { ...state, user, loading: false };
    case "SET_LOGGED_IN":
      return { ...state, isLoggedIn: action.payload };
    case "SET_LOADING":
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

export default userReducer;
