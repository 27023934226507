function Product({
  id,
  name,
  price,
  description,
  type,
  group,
  nameEnglish,
  descriptionEnglish
}) {
  this.id = id;
  this.name = name;
  this.price = price;
  this.type = type;
  this.group = group;
  this.description = description;
  this.nameEnglish = nameEnglish;
  this.descriptionEnglish = descriptionEnglish;
}

export default Product;
