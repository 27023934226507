import React, { forwardRef } from "react";
import styled, { css } from "styled-components";

const Field = styled.div`
  font-size: 22px;
  position: relative;
  color: ${props => props.theme.colors.secondary};
  ${props =>
    props.theme.isError &&
    css`
      border: 1px solid ${props.theme.colors.error};
    `};
`;

const Area = styled.textarea`
  border: 0;
  width: 100%;
  min-height: 150px;
  padding: 10px;
  font-size: 22px;
  resize: none;
  color: ${props => props.theme.colors.secondary};
  background: ${props => props.theme.colors.transparentWhite};
  -moz-appearance: textfield;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

const Label = styled.div`
  position: absolute;
  top: 10px;
  left: 0;
  margin-left: ${props => (props.theme.hasIcon ? "45px" : "10px")};
  transition: all 0.5s;
  pointer-events: none;
  color: ${props =>
    props.theme.isError
      ? props.theme.colors.error
      : props.theme.colors.transparentSecondary};
  ${Area}:focus ~ & {
    transform: translate(0);
    top: -30px;
    margin: 0;
    font-size: 18px;
    color: ${props =>
      props.theme.isError
        ? props.theme.colors.error
        : props.theme.colors.secondary};
  }
  ${props =>
    props.isThereValue &&
    css`
      transform: translate(0);
      top: -30px;
      margin: 0;
      font-size: 18px;
      color: ${props =>
        props.theme.isError
          ? props.theme.colors.error
          : props.theme.colors.secondary};
    `}
`;

const TextArea = forwardRef(({ value, label, required, ...props }, ref) => {
  return (
    <Field>
      <Area ref={ref} value={value} {...props} required={required} />
      <Label isThereValue={!!value}>
        {label}
        {required && "*"}
      </Label>
    </Field>
  );
});

export default TextArea;
