import React from "react";
import styled from "styled-components";
import { Selector } from "components/utils";
import { BOOLEAN_STATE } from "utils/contants";
import { useTranslation } from "react-i18next";

const CustomSelector = styled(Selector)`
  padding: 0;
  width: 70%;
  min-width: 140px;
  select {
    height: 40px;
    text-align-last: center;
  }
  option {
    text-align: center;
  }
`;

const BooleanSelector = ({ name, defaultValue, onChange }) => {
  const { t: translate } = useTranslation();
  const states = Object.keys(BOOLEAN_STATE);

  const handleChange = e => {
    const { name, value } = e.target;
    onChange(name, JSON.parse(value));
  };

  return (
    <CustomSelector
      name={name}
      defaultValue={defaultValue}
      onChange={handleChange}
    >
      {states.map((key, index) => (
        <option key={index} value={key}>
          {translate(`${BOOLEAN_STATE[key]}`)}
        </option>
      ))}
    </CustomSelector>
  );
};

export default BooleanSelector;
