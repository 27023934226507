import React from "react";
import { TextField, LoaderButton, Private } from "components/utils";
import ProductDetails from "./product-details";
import AllergenMarker from "./allergen-marker";
import { TypeSelector, GroupSelector, OrderableSelector } from "./selectors";
import { PRODUCT_TYPES, ROLES } from "utils/contants";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 80%;
  margin: 20px auto;
  @media (max-width: 900px) {
    width: 100%;
  }
`;

const GridWrapp = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const HalfWrapp = styled.div`
  width: calc(50% - 10px);
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ProductForm = ({
  values = {},
  handleChange,
  allergenChange,
  onSubmit,
  edit = false,
  loading
}) => {
  const { t: translate } = useTranslation();
  const { ADMIN, MANAGER } = ROLES;

  return (
    <Wrapper>
      <form onSubmit={onSubmit}>
        <Private roles={[ADMIN, MANAGER]}>
          <GridWrapp>
            <ProductDetails values={values} onChange={handleChange} />
            <TypeSelector value={values.type} onChange={handleChange} />
            <GroupSelector
              type={PRODUCT_TYPES[values.type]}
              value={values.group}
              onChange={handleChange}
            />
          </GridWrapp>
          <HalfWrapp>
            <AllergenMarker
              show={PRODUCT_TYPES[values.type] === PRODUCT_TYPES.FOOD}
              values={values.allergenics}
              onChange={allergenChange}
            />
          </HalfWrapp>
          <GridWrapp>
            <TextField
              name="price"
              type="number"
              label={translate("products.details.price")}
              value={values.price}
              onChange={handleChange}
              required
            />
            <TextField
              name="loquuId"
              type="number"
              label="Loquu ID"
              value={values.loquuId}
              onChange={handleChange}
              required
            />
          </GridWrapp>
        </Private>
        <HalfWrapp>
          <OrderableSelector value={values.orderable} onChange={handleChange} />
          <LoaderButton
            type="submit"
            text={
              edit ? translate("products.edit") : translate("products.addNew")
            }
            loading={loading}
          />
        </HalfWrapp>
      </form>
    </Wrapper>
  );
};

export default ProductForm;
