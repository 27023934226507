const cartReducer = (state, action) => {
  switch (action.type) {
    case "SET_CART":
      return { ...state, ...action.payload };
    case "SET_COUPON":
      return { ...state, coupon: action.payload };
    default:
      return state;
  }
};

export default cartReducer;
