import React from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import {
  PersonalInfo,
  ProfileNavigation,
  Addresses,
  Orders
} from "components/profile";
import { Container } from "style/global.styled";

const Wrapper = styled.div`
  width: 80%;
  min-height: 60vh;
  margin: 0 auto;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const Content = styled.div`
  padding: 20px 30px;
  @media (max-width: 768px) {
    padding: 20px 10px;
  }
`;

const Profile = () => {
  const { subpage = "" } = useParams();
  return (
    <Container>
      <Wrapper>
        <ProfileNavigation />
        <Content>
          {subpage === "" && <PersonalInfo />}
          {subpage === "addresses" && <Addresses />}
          {subpage === "orders" && <Orders />}
        </Content>
      </Wrapper>
    </Container>
  );
};

export default Profile;
