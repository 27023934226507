import React, { useEffect } from "react";
import Selector from "../selector";
import { getZipCodes } from "utils/dataHandler";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const CitySelector = ({ ...props }) => {
  const { t: translate } = useTranslation();
  const [zipcodes, setZipcodes] = useState(null);

  useEffect(() => {
    getZipCodes().then(zipcodes => setZipcodes(zipcodes));
  }, []);

  return (
    <Selector {...props} emptyText={translate("requestInProgress")}>
      {zipcodes &&
        zipcodes.map(zipcode => (
          <option key={zipcode} value={zipcode}>
            {zipcode}
          </option>
        ))}
    </Selector>
  );
};

export default CitySelector;
